import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { takeLatest } from 'redux-saga/effects';
import createRequestSaga, {
  createRequestActionTypes,
} from '../lib/createRequestSaga';
import * as searchAPI from '../lib/api/search';

const CHANGE_FIELD = 'search/CHANGE_FIELD';
const INITIALIZE_FILTER_FORM = 'search/INITIALIZE_FILTER_FORM';

const [MULTI_SEARCH, MULTI_SEARCH_SUCCESS, MULTI_SEARCH_FAILURE] =
  createRequestActionTypes('search/MULTI_SEARCH');

const [SINGLE_SEARCH, SINGLE_SEARCH_SUCCESS, SINGLE_SEARCH_FAILURE] =
  createRequestActionTypes('search/SINGLE_SEARCH');

const [FAILED_SEARCH, FAILED_SEARCH_SUCCESS, FAILED_SEARCH_FAILURE] =
  createRequestActionTypes('search/FAILED_SEARCH');

export const changeSearchField = createAction(
  CHANGE_FIELD,
  ({ form, key, value }) => ({
    form,
    key,
    value,
  }),
);

export const initializeFilterForm = createAction(
  INITIALIZE_FILTER_FORM,
  (form) => form,
);

export const multiSearch = createAction(
  MULTI_SEARCH,
  ({
    devOpsDiv,
    dataCnt,
    schoolDiv,
    actorId,
    edAppId,
    eventType,
    edAppNameList,
  }) => ({
    devOpsDiv,
    dataCnt,
    schoolDiv,
    actorId,
    edAppId,
    eventType,
    edAppNameList,
  }),
);
export const singleSearch = createAction(
  SINGLE_SEARCH,
  ({
    devOpsDiv,
    dataCnt,
    schoolDiv,
    actorId,
    edAppId,
    eventType,
    edAppNameList,
  }) => ({
    devOpsDiv,
    dataCnt,
    schoolDiv,
    actorId,
    edAppId,
    eventType,
    edAppNameList,
  }),
);

export const failedSearch = createAction(
  FAILED_SEARCH,
  ({
    devOpsDiv,
    dataCnt,
    schoolDiv,
    actorId,
    edAppId,
    eventType,
    edAppNameList,
    timeRange,
  }) => ({
    devOpsDiv,
    dataCnt,
    schoolDiv,
    actorId,
    edAppId,
    eventType,
    edAppNameList,
    timeRange,
  }),
);

const multiSearchSaga = createRequestSaga(
  MULTI_SEARCH, 
  searchAPI.multiSearch
);

const singleSearchSaga = createRequestSaga(
  SINGLE_SEARCH,
  searchAPI.singleSearch
);

const failedSearchSaga = createRequestSaga(
  FAILED_SEARCH,
  searchAPI.failedSearch
);
export function* searchSaga() {
  yield takeLatest(MULTI_SEARCH, multiSearchSaga);
  yield takeLatest(SINGLE_SEARCH, singleSearchSaga);
  yield takeLatest(FAILED_SEARCH, failedSearchSaga);
}

const initialState = {
  filter: {
    devOpsDiv: {
      name: 'Dev',
      value: 'dev',
    },
    dataCnt: '400',
    schoolDiv: {
      name: '초등',
      value: 'es',
    },
    actorId: '',
    eventType: [
      {
        label: 'SessionEvent',
        name: 'session',
        value: true,
      },
      {
        label: 'ToolUseEvent',
        name: 'toolUse',
        value: true,
      },
      {
        label: 'NavigationEvent',
        name: 'navigation',
        value: true,
      },
      {
        label: 'AssignableEvent',
        name: 'assignable',
        value: true,
      },
      {
        label: 'ViewEvent',
        name: 'view',
        value: true,
      },
      {
        label: 'AssessmentEvent',
        name: 'assessment',
        value: true,
      },
      {
        label: 'AssessmentItemEvent',
        name: 'assessmentItem',
        value: true,
      },
      {
        label: 'GradeEvent',
        name: 'grade',
        value: true,
      },
      {
        label: 'MediaEvent',
        name: 'media',
        value: true,
      },
      {
        label: 'MessageEvent',
        name: 'message',
        value: true,
      },
      {
        label: 'AnnotationEvent',
        name: 'annotation',
        value: true,
      },
      {
        label: 'SearchEvent',
        name: 'search',
        value: true,
      },
      {
        label: 'SurveyEvent',
        name: 'survey',
        value: true,
      },
      {
        label: 'QuestionnaireEvent',
        name: 'questionnaire',
        value: true,
      },
    ],
    edAppNameList: [],
    timeRange: {
      name: '1시간 전',
      value: '1',
    },
  },
  eventLog: [],
  searchError: null,
};

const search = handleActions(
  {
    [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
      produce(state, (draft) => {
        draft[form][key] = value;
      }),
    [INITIALIZE_FILTER_FORM]: (state, { payload: form }) => ({
      ...state,
      [form]: initialState[form],
      eventLog: null,
    }),
    [MULTI_SEARCH_SUCCESS]: (state, { payload: eventLog }) => ({
      ...state,
      eventLog: eventLog.data,
      searchError: null,
    }),
    [MULTI_SEARCH_FAILURE]: (state, { payload: error }) => ({
      ...state,
      eventLog: [],
      searchError: error,
    }),
    [SINGLE_SEARCH_SUCCESS]: (state, { payload: eventLog }) => ({
      ...state,
      eventLog: eventLog.data,
      searchError: null,
    }),
    [SINGLE_SEARCH_FAILURE]: (state, { payload: error }) => ({
      ...state,
      eventLog: [],
      searchError: error,
    }),
    [FAILED_SEARCH_SUCCESS]: (state, { payload: eventLog }) => ({
      ...state,
      eventLog: eventLog.data,
      searchError: null,
    }),
    [FAILED_SEARCH_FAILURE]: (state, { payload: error }) => ({
      ...state,
      eventLog: [],
      searchError: error,
    }),
  },
  initialState,
);

export default search;
