import React from 'react';
import LoadingSpinner from '../common/LoadingSpinner';

const AdminForm = ({ onUserClick, loading, userList, user }) => {
    return (
        <>
        {!loading ? (
        <div className="admin-boxes">
            <div className="main box">
                <div className="button-area">
                    <button>Sign up</button>
                    <button>EdApp List</button>
                </div>
                <div className="title">User List</div>
                <div className="admin-details">
                    <table>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>ID</th>
                                <th>User Name</th>
                                <th>Email</th>
                                <th>ACCOUNT_TYPE</th>
                                <th>SCHOOL_TYPE</th>
                                <th>DEV/OPS</th>
                                <th>EDAPP_NAME</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                userList.map((value, index) => {
                                    return (
                                        <tr onClick={() => onUserClick(value.userId)} key={index}>
                                            <td>{index + 1}</td>
                                            <td>{value.userId}</td>
                                            <td>{value.userName}</td>
                                            <td>{value.email}</td>
                                            <td>{value.adminYn}</td>
                                            <td>{value.schoolDiv}</td>
                                            <td>{value.devOpsDiv}</td>
                                            <td>{value.edAppName}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        ): (
            <div className="admin-boxes">
                <div className="main box">
                    <div className="title">User List</div>
                    <div className="admin-details">
                        <table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ID</th>
                                    <th>User Name</th>
                                    <th>Email</th>
                                    <th>ACCOUNT_TYPE</th>
                                    <th>SCHOOL_TYPE</th>
                                    <th>DEV/OPS</th>
                                    <th>EDAPP_NAME</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                </div>
                <LoadingSpinner />
            </div>
        )}
    </>
    );
};

export default AdminForm;