import React from 'react';
import { Link } from 'react-router-dom';

const AuthTemplate = ({ children }) => {
    return (
        <div className="login">
            <div className="white-box">
                <div className="logo-area">
                    <Link to="/">EVENT VIEWER</Link>
                </div>
                {children}
            </div>
        </div>
    );
};

export default AuthTemplate;