import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import SearchFilter from '../../components/common/SearchFilter';
import { changeSearchField, failedSearch, multiSearch, singleSearch } from '../../modules/search';
const SearchFilterContainer = ({ type }) => {
    const [sticky, setSticky] = useState(false);
    const [tagsInputValue, setTagsInputValue ] = useState("");
    const [scrollTop, setScrollTop] = useState(0);
    const dispatch = useDispatch();
    const { search, searchError, user, loading } = useSelector(({ multiSearch, user, loading }) => ({
        search: multiSearch.filter,
        searchError: multiSearch.searchError,
        user: user.user,
        loading: loading["search/MULTI_SEARCH"],
    }));
    const [isAllCheck, setIsAllCheck] = useState(true);
    const history = useHistory();

    useEffect(() => {
        function onScroll() {
            let currentPosition = window.scrollY;

            if(currentPosition > 20) {
                setSticky(true);
                let root = document.querySelector(":root");
                root.style.setProperty("--home-content-margin-top", "405px");
            } else {
                setSticky(false);
                let root = document.querySelector(":root");
                root.style.setProperty("--home-content-margin-top", "70px");
            }
            setScrollTop(currentPosition <= 0 ? 0 : currentPosition);
        }

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [scrollTop]);

    const onChange = e => {
        const { value, name } = e.target;
        dispatch(
            changeSearchField({
                form: 'filter',
                key: name,
                value
            })
        );
    };

    const onSearchClick = () => {
        type === "single" ? (
            dispatch(
                singleSearch(
                    search
                )
            )
        ) : type === "multi" ? (
            dispatch(
                multiSearch(
                    search
                )
            )
        ) : (
            dispatch(
                failedSearch(
                    search
                )
            )
        )
    };
    const handleSelectOptionClick = (id, value) => {
        dispatch(
            changeSearchField({
                form: 'filter',
                key: id,
                value,
            })
        )
    }
    const onChangeEdAppNameInput = e => {
        const { value } = e.target;

        setTagsInputValue(value);
    }

    const handleAddEdAppName = e => {
        const { value } = e.target;
        let newEdAppList = [...search.edAppNameList];
        if( e.keyCode === 13) {
            if(tagsInputValue !== "") {
                newEdAppList.push(value);
                newEdAppList = [...new Set(newEdAppList)];
                dispatch(
                    changeSearchField({
                        form: 'filter',
                        key: 'edAppNameList',
                        value: newEdAppList,
                    })
                )
                setTagsInputValue("");
            }
        } else if (e.keyCode === 8) {
            if(tagsInputValue === "") {
                newEdAppList.pop();
                dispatch(
                    changeSearchField({
                        form: 'filter',
                        key: 'edAppNameList',
                        value: newEdAppList,
                    })
                )
            } else {
                setTagsInputValue(value);
            }
        } else {
            setTagsInputValue(value);
        }
    }

    const handleRemoveEdAppName = removeValue => {
        let newEdAppList = [...search.edAppNameList].filter((value) => {
            return value !== removeValue;
        });

        dispatch(
            changeSearchField({
                form: 'filter',
                key: 'edAppNameList',
                value: newEdAppList,
            })
        )
    }

    const onClickCheckBox = label => {
        let nextCheckBoxList = [];
        //let check = search.eventType[0].value;
        let check = true;
        if(label === 'All') {
            search.eventType.map((value) => {
                    nextCheckBoxList.push({...value, value: !isAllCheck});
            });
            setIsAllCheck(!isAllCheck);
            onArrayChange(nextCheckBoxList);
        } else {
            search.eventType.map((value) => {
                if(value.label === label) {
                    nextCheckBoxList.push({...value, value: !value.value});
                    check &= !value.value;
                }
                else {
                    nextCheckBoxList.push(value);
                    check &= value.value;
                }
            });
            onArrayChange(nextCheckBoxList);
            setIsAllCheck(check);
        }
    }

    const onClickRadioBox = label => {
        const nextCheckBoxList = [];
        search.eventType.map((value) => {
            if(value.label === label) {
                nextCheckBoxList.push({...value, value: true});
            } else {
                nextCheckBoxList.push({...value, value: false});
            }
        })
        onArrayChange(nextCheckBoxList);
    }

    const onArrayChange = checkBoxList => {
        dispatch(
            changeSearchField({
                form: 'filter',
                key: 'eventType',
                value: checkBoxList
            }),
        );
    };



    return <SearchFilter
                tagsInputValue={tagsInputValue}
                onChangeEdAppNameInput={onChangeEdAppNameInput}
                handleRemoveEdAppName={handleRemoveEdAppName}
                loading={loading}
                search={search} 
                user={user}
                sticky={sticky} 
                onChange={onChange}
                isAllCheck={isAllCheck}
                onClickCheckBox={onClickCheckBox}
                onClickRadioBox={onClickRadioBox}
                handleSelectOptionClick={handleSelectOptionClick}
                handleAddEdAppName={handleAddEdAppName}
                onSearchClick={onSearchClick}
                type={type}
                />
}

export default SearchFilterContainer;