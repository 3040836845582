import React from 'react';
import SearchFilterContainer from '../../containers/common/SearchFilterContainer';
import LoadingSpinner from '../common/LoadingSpinner';
import Select from '../common/Select';

const indexValue = [
  {
    name: '200',
    value: '200',
  },
  {
    name: '400',
    value: '400',
  },
  {
    name: '600',
    value: '600',
  },
  {
    name: '800',
    value: '800',
  },
  {
    name: '1000',
    value: '1000',
  },
];

const FailedEventForm = ({
  onClickActorId,
  onClickEdAppName,
  eventLog,
  searchError,
  loading,
  handleSelectOptionClick,
  filter,
}) => {
  return (
    <>
      <SearchFilterContainer type="failed" />
      <div className="view-boxes">
        <div className="recent-sale box">
          <div className="top-space">
            <div className="title">Failed Event Log</div>
            <div className="select-index">
              <Select
                placeholder="INDEX"
                id="dataCnt"
                options={indexValue}
                value={filter.dataCnt}
                handleSelectOptionClick={handleSelectOptionClick}
              />
            </div>
          </div>
          {!loading ? (
            <div className="view-details">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>EVENT_TIME</th>
                    <th>EVENT_TYPE</th>
                    <th>ACTION_TYPE</th>
                    <th>ERROR_KEY</th>
                    <th>ERROR_VALUE</th>
                    <th>SERVICE_NAME</th>
                    <th>ACTOR_ID</th>
                    <th>ED_APP_NAME</th>
                  </tr>
                </thead>
                <tbody>
                  {eventLog
                    ? eventLog.map((value, index) => {
                        return (
                          <tr className={`${value.action}`} key={index}>
                            <td>{index + 1}</td>
                            <td className="event-time">{value.eventtime}</td>
                            <td>{value.type}</td>
                            <td>{value.action}</td>
                            <td>{value.errorKey}</td>
                            <td>{value.errorValue}</td>
                            <td>{value.serviceName}</td>
                            <td>
                              <label
                                className="cursor"
                                onClick={() => onClickActorId(value.actorId)}
                              >
                                {value.actorId}
                              </label>
                            </td>
                            <td>
                              <label
                                className="cursor"
                                onClick={() =>
                                  onClickEdAppName(value.edAppName)
                                }
                              >
                                {value.edAppName}
                              </label>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          ) : (
            <div className="view-details">
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>EVENT_TIME</th>
                    <th>EVENT_TYPE</th>
                    <th>ACTION_TYPE</th>
                    <th>ERROR_KEY</th>
                    <th>ERROR_VALUE</th>
                    <th>SERVICE_NAME</th>
                    <th>ACTOR_ID</th>
                    <th>ED_APP_NAME</th>
                  </tr>
                </thead>
              </table>
              <LoadingSpinner />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FailedEventForm;
