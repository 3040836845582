import React, { useState } from 'react'

const Select = ({ options, placeholder, value, id, handleSelectOptionClick }) => {
    const [ isActive, setIsActive ] = useState(false);

    const handleActive = () => {
        setIsActive(!isActive);
    }
    const handleOptionClick = () => {
        setIsActive(false);
    }

    return (
        <>
            <div className="select-box" 
                onClick={() => handleActive()}
                onMouseLeave={() => handleOptionClick()}>
                <div className={`selected ${isActive ? "active" : ""}`}>
                    <label className={`${!value ? "" : "value"}`}>
                        {!value ? placeholder : value}
                    </label>
                    <i className={`fas fa-caret-down`} />    
                </div>
                <div className={`underline`}></div>
                <div className={`activeline ${isActive ? "active" : ""}`}></div>
                <div className={`options-container ${isActive ? "active" : ""}`}>
                    { options.map((value, index) => {
                        return(
                            <div className="option" onClick={() => handleSelectOptionClick(id, value)} key={index}>
                                <input type="radio" className="radio" id={value.value} name={value.id} />
                                <label>{value.name}</label>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default Select;