import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FailedEventForm from '../../components/eventview/FailedEventForm';
import { changeSearchField, failedSearch, singleSearch } from '../../modules/search';
import { initializeForm } from '../../modules/user';
import { check } from '../../modules/user';

const FailedEventContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    filter,
    eventLog,
    searchError,
    tokenCheck,
    tokenCheckError,
    loading,
  } = useSelector(({ multiSearch, user, loading }) => ({
    filter: multiSearch.filter,
    eventLog: multiSearch.eventLog,
    searchError: multiSearch.searchError,
    tokenCheck: user.check,
    tokenCheckError: user.checkError,
    loading: loading['search/FAILED_SEARCH'],
  }));

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(check());
    }
  }, [dispatch]);

  useEffect(() => {
    dispatch(failedSearch(filter));
  }, [dispatch]);

  useEffect(() => {
    if (tokenCheckError) {
      localStorage.removeItem('token');
      localStorage.removeItem('edAppList');
      localStorage.removeItem('user');
      dispatch(initializeForm('user'));
      history.push('/login');
    }
  }, [dispatch, tokenCheck, tokenCheckError]);

  const handleSelectOptionClick = (id, value) => {
    dispatch(
      changeSearchField({
        form: 'filter',
        key: id,
        value: value.value,
      }),
    );
  };

  const onClickActorId = (value) => {
    dispatch(
      changeSearchField({
        form: 'filter',
        key: 'actorId',
        value,
      }),
    );
  };

  const onClickEdAppName = (value) => {
    let newEdAppNameList = [...filter.edAppNameList];
    newEdAppNameList.push(value);
    newEdAppNameList = [...new Set(newEdAppNameList)];
    dispatch(
      changeSearchField({
        form: 'filter',
        key: 'edAppNameList',
        value: newEdAppNameList,
      }),
    );
  };

  return (
    <FailedEventForm
      filter={filter}
      loading={loading}
      eventLog={eventLog}
      searchError={searchError}
      onClickActorId={onClickActorId}
      onClickEdAppName={onClickEdAppName}
      handleSelectOptionClick={handleSelectOptionClick}
    />
  );
};

export default FailedEventContainer;
