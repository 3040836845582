import React from 'react';
import SearchFilterContainer from '../../containers/common/SearchFilterContainer';
import { successEvent } from '../../modules/sample/sample';
import LoadingSpinner from '../common/LoadingSpinner';
import Select from '../common/Select';

const indexValue = [
  {
    name: '200',
    value: '200',
  },
  {
    name: '400',
    value: '400',
  },
  {
    name: '600',
    value: '600',
  },
  {
    name: '800',
    value: '800',
  },
  {
    name: '1000',
    value: '1000',
  },
];

const ConvertCameltoSnake = text => {
  return text.split(/(?=[A-Z])/).join('_').toUpperCase();
}

const EventTypeViewForm = ({
  onClickActorId,
  onClickEdAppName,
  eventLog,
  searchError,
  loading,
  handleSelectOptionClick,
  eventLogHeader,
  filter,
}) => {
  return (
    <>
      <SearchFilterContainer 
        type="single" />
      <div className="view-boxes">
        <div className="recent-sale box">
          <div className="top-space">
            <div className="title">Event Log</div>
            <div className="select-index">
              <Select
                placeholder="INDEX"
                id="dataCnt"
                options={indexValue}
                value={filter.dataCnt}
                handleSelectOptionClick={handleSelectOptionClick}
              />
            </div>
          </div>

          {!loading ? (
              <div className="view-details">
                <table>
                  <thead>
                    <tr>
                      <th>#</th>
                      {
                        eventLog.length > 0 ? (
                          eventLogHeader.map((value, index) => {
                            return (
                              <th key={index}>{index > 3 ? ConvertCameltoSnake(value) : value}</th>
                            )
                          })
                        ) : null
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {eventLog
                      ? eventLog.map((value, index) => {
                          return (
                            <tr className={`${value.action}`} key={index}>
                              <td>{index + 1}</td>
                              
                              <td className="event-time">{value.eventtime}</td>
                              <td>{value.action}</td>
                              <td>
                                <label
                                  className="cursor"
                                  onClick={() => onClickActorId(value.actorId)}
                                >
                                  {value.actorId}
                                </label>
                              </td>
                              <td>
                                <label
                                  className="cursor"
                                  onClick={() =>
                                    onClickEdAppName(value.edAppName)
                                  }
                                >
                                  {value.edAppName}
                                </label>
                              </td>
                              {
                                eventLogHeader.map((header, index) => {
                                  if(index > 3){
                                    return (
                                      <td key={index}>
                                        {value[header]}
                                      </td>
                                    )
                                  }
                                })
                              }
                            </tr>
                          );
                        })
                      : null}
                  </tbody>
                </table>
              </div>
            ) : (
            <div className="view-details">
              <LoadingSpinner />
            </div>
          )}
          {/* <div className="view-details">
                    <table>
                        <thead>
                            <th>EVENT_TIME</th>
                            <th>EVENT_TYPE</th>
                            <th>ACTION_TYPE</th>
                            <th>ACTOR_ID</th>
                            <th>ED_APP_NAME</th>
                        </thead>
                        <tbody>
                            {
                                successEvent.data.map((value, index) => {
                                    return (
                                        <tr className={`${value.action}`}>
                                            <td className="event-time">{value.eventtime}</td>
                                            <td>{value.type}</td>
                                            <td>{value.action}</td>
                                            <td>
                                                <label className="cursor" onClick={() => onClickActorId(value.actorId)}>
                                                    {value.actorId}
                                                </label>
                                            </td>
                                            <td>{value.edAppName}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div> */}
        </div>
      </div>
    </>
  );
};

export default EventTypeViewForm;
