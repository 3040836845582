import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Header from '../../components/common/Header';
import { initializeForm } from '../../modules/auth';
import { SetUser } from '../../modules/user';

const HeaderContainer = () => {
    const { user } = useSelector(({ user }) => ({ user: user.user}));
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        if (storedUser) {
            dispatch(
                SetUser(
                    storedUser
                )
            )
        }
    },[dispatch]);

    const onClickLogOut = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('edAppList');
        localStorage.removeItem('user');
        dispatch(
            SetUser(
                null
            )
        );
        dispatch(
            initializeForm('auth')
        );
        history.push('/login');
    }

    return (
        <Header user={user} onClickLogOut={onClickLogOut} />
    );
}

export default HeaderContainer;