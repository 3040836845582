import React from 'react';

const CheckBoxList = ({ list, onClickCheckBox, isAllCheck, type, onClickRadioBox }) => {
  return type === "multi" || type === "failed" ? (
    <div className="checkbox-box">
      <div className="name">EVENT_TYPE</div>
      <div className="checkbox-list">
        <div className="row">
          <div className={`check-box ${isAllCheck ? "checked" : ""}`} onClick={() => onClickCheckBox('All')}>
            <input type="checkbox" checked={isAllCheck} readOnly />
            <label>All</label>
          </div>
          {list.map((value, index) => {
            if (index <= ((list.length / 2) - 1)) {
              return (
                <div className={`check-box ${value.value ? "checked" : ""}`} onClick={() => onClickCheckBox(value.label)} key={index}>
                  <input type="checkbox" checked={value.value} readOnly />
                  <label>{value.label}</label>
                </div>
              );
            }
            return true;
          })}
        </div>
      </div>
      <div className="checkbox-list">
        <div className="row">
          {list.map((value, index) => {
            if (index >= ((list.length / 2) - 1)) {
              return (
                <div className={`check-box ${value.value ? "checked" : ""}`} onClick={() => onClickCheckBox(value.label)} key={index}>
                  <input type="checkbox" checked={value.value} readOnly />
                  <label>{value.label}</label>
                </div>
              );
            }
            return true;
          })}
        </div>
      </div>
    </div>
  ) : (
    <div className="checkbox-box">
      <div className="name">EVENT_TYPE</div>
      <div className="checkbox-list">
        <div className="row">
          {list.map((value, index) => {
            if (index < (list.length / 2)) {
              return (
                <div className={`check-box ${value.value ? "checked" : ""}`} onClick={() => onClickRadioBox(value.label)} key={index}>
                  <input type="radio" checked={value.value} readOnly />
                  <label>{value.label}</label>
                </div>
              );
            }
            return true;
          })}
        </div>
      </div>
      <div className="checkbox-list">
        <div className="row">
          {list.map((value, index) => {
            if (index >= (list.length / 2)) {
              return (
                <div className={`check-box ${value.value ? "checked" : ""}`} onClick={() => onClickRadioBox(value.label)} key={index}>
                  <input type="radio" checked={value.value} readOnly />
                  <label>{value.label}</label>
                </div>
              );
            }
            return true;
          })}
        </div>
      </div>
    </div>
  )
};

export default CheckBoxList;
