export const eventType = [
  {
    label: 'SessionEvent',
  },
  {
    label: 'ToolUseEvent',
  },
  {
    label: 'NavigationEvent',
  },
  {
    label: 'AssignableEvent',
  },
  {
    label: 'ViewEvent',
  },
  {
    label: 'AssessmentEvent',
  },
  {
    label: 'AssessmentItemEvent',
  },
  {
    label: 'GradeEvent',
  },
  {
    label: 'MediaEvent',
  },
  {
    label: 'MessageEvent',
  },
  {
    label: 'AnnotationEvent',
  },
  {
    label: 'SearchEvent',
  },
  {
    label: 'SurveyEvent',
  },
  {
    label: 'QuestionnaireEvent',
  },
];
export const schoolType = [
  {
    name: '초등',
    value: 'es',
  },
  {
    name: '중등',
    value: 'ms',
  },
];

export const serverType = [
  {
    name: 'Dev',
    value: 'dev',
  },
  {
    name: 'Ops',
    value: 'ops',
  },
];

export const timeRange = [
  {
    name: '1시간 전',
    value: '1'
  },
  {
    name: '2시간 전',
    value: '2'
  },
  {
    name: '4시간 전',
    value: '4'
  },
  {
    name: '6시간 전',
    value: '6'
  },
  {
    name: '12시간 전',
    value: '12'
  },
  {
    name: '하루 전',
    value: '24'
  },
]
