import React from 'react';
import MainTemplate from '../components/main/MainTemplate';
import EventViewContainer from '../containers/eventview/EventViewContainer';

const MainPage = () => {
  return (
    <MainTemplate>
      <EventViewContainer />
    </MainTemplate>
  );
};

export default MainPage;