import React from 'react';

const Input = ({ name, value, onChange, placeholder }) => {
    return (
        <div className="input-data">
            <input name={name} onChange={onChange} value={value} type="text" required/>
            <label htmlFor="">{placeholder}</label>
            <div className="underline"></div>
        </div>
    )
}

export default Input;