import React, { useState } from 'react';
import { useHistory } from 'react-router';
import palette from '../../lib/styles/palette';
import { eventType } from '../../modules/sample/sample';


const Header = ({ user, onClickLogOut }) => {
    const history = useHistory();
    const [ isPaletteClick, setIsPaletteClick ] = useState(false);
    const [ isHover, setIsHover ] = useState(false);
    const [ isMenuButtonClick, setIsMenuButtonClick ] = useState(false);
    const [buttonColor, setButtonColor] = useState([
        {color: 'blue', active: true},
        {color: 'orange', active: false},
        {color: 'purple', active: false},
        {color: 'green', active: false},
        {color: 'light-dark', active: false}
    ])

    const handlePaletteClick = () => {
        setIsPaletteClick(!isPaletteClick);
    }

    const handleDarkModeClick = () => {
        if(!buttonColor[4].active) {
            let CurrentTheme = palette['light-dark'];

            let root = document.querySelector(":root");
            root.style.setProperty("--white", CurrentTheme.white);
            root.style.setProperty("--black", CurrentTheme.black);
            root.style.setProperty("--nav-main", CurrentTheme.navMain);
            root.style.setProperty("--switchers-main", CurrentTheme.switchersMain);
            root.style.setProperty("--light-bg", CurrentTheme.body);


            let currentButtonColor = [];

            buttonColor.map((value) => {
                if(value.color === 'light-dark') {
                    return currentButtonColor.push({color: value.color, active: true});
                } else {
                    return currentButtonColor.push({color: value.color, active: false});
                }
            })
            setButtonColor(currentButtonColor);
        } else {
            let CurrentTheme = palette['blue'];

            let root = document.querySelector(":root");
            root.style.setProperty("--white", CurrentTheme.white);
            root.style.setProperty("--black", CurrentTheme.black);
            root.style.setProperty("--nav-main", CurrentTheme.navMain);
            root.style.setProperty("--switchers-main", CurrentTheme.switchersMain);
            root.style.setProperty("--light-bg", CurrentTheme.body);


            let currentButtonColor = [];

            buttonColor.map((value) => {
                if(value.color === 'blue') {
                    return currentButtonColor.push({color: value.color, active: true});
                } else {
                    return currentButtonColor.push({color: value.color, active: false});
                }
            })
            setButtonColor(currentButtonColor);
        }

        setIsPaletteClick(false);
    }

    const onMenuClick = label => {
        localStorage.setItem('eventType', label);
        history.push('/singleview');
    }

    // const handleChangeTheme = (e) => {
    //     console.log(e.target);
    //     let target = e.target.className.split(" ");

    //     if(target[2] !== 'active') {
    //         let CurrentTheme = palette[target[1]];

    //         let root = document.querySelector(":root");
    //         root.style.setProperty("--white", CurrentTheme.white);
    //         root.style.setProperty("--black", CurrentTheme.black);
    //         root.style.setProperty("--nav-main", CurrentTheme.navMain);
    //         root.style.setProperty("--switchers-main", CurrentTheme.switchersMain);


    //         let currentButtonColor = [];

    //         buttonColor.map((value,index) => {
    //             if(value.color === target[1]) {
    //                 currentButtonColor.push({color: value.color, active: true});
    //             } else {
    //                 currentButtonColor.push({color: value.color, active: false});
    //             }
    //         })
    //         setIsPaletteClick(!isPaletteClick);
    //         setButtonColor(currentButtonColor);
    //     } else {
    //         setIsPaletteClick(!isPaletteClick);
    //     }
    // }

    const handleMenuHover = () => {
        setIsHover(!isHover);
    }
    const handleMenuButtonClick = () => {
        setIsMenuButtonClick(!isMenuButtonClick);
        console.log(isMenuButtonClick);
    }
   
    return (
        <nav>
            <div className="navbar">
                <i className="fas fa-bars" onClick={() => handleMenuButtonClick()} />
                <div className="logo">
                    <a href="/home">Event Viewer</a>
                </div>
                <ul className={`nav-links ${isMenuButtonClick ? "open" : ""}`}>
                    <div className="sidebar-logo">
                        <span className="logo_name">Event Viewer</span>
                        <i className="fas fa-times" onClick={() => handleMenuButtonClick()} />
                    </div>
                    <li><a href="/home" className="">Home</a></li>
                    <li
                        onMouseEnter={() => handleMenuHover()}
                        onMouseLeave={() => handleMenuHover()}
                    >
                        <a href="/singleview">
                            EventTypeView {" "}
                            <i className={`fas fa-caret-down ${isHover ? "open" : ""}`} />
                        </a>
                        <ul className="eventTypeView-sub-menu sub-menu">
                            {
                                eventType.map((value, index) => {
                                    return (
                                        <li key={index} onClick={() => onMenuClick(value.label)}>
                                            {value.label}
                                        </li>
                                    )
                                })
                            }
                            {/* <li><a href="#">SessionEvent</a></li>
                            <li><a href="#">ToolUseEvent</a></li>
                            <li><a href="#">NavigationEvent</a></li>
                            <li><a href="#">AssignableEvent</a></li>
                            <li><a href="#">ViewEvent</a></li>
                            <li><a href="#">AssessmentEvent</a></li>
                            <li><a href="#">AssessmentItemEvent</a></li>
                            <li><a href="#">GradeEvent</a></li>
                            <li><a href="#">MediaEvent</a></li>
                            <li><a href="#">MessageEvent</a></li>
                            <li><a href="#">AnnotationEvent</a></li>
                            <li><a href="#">SearchEvent</a></li>
                            <li><a href="#">SurveyEvent</a></li>
                            <li><a href="#">QuestionnaireEvent</a></li> */}
                        </ul>
                    </li>
                    <li>
                        <a href="/failed">FailedEventView</a>
                    </li>
                    {
                        user ? user.adminYn === "Y" ? (
                            <li><a href="/admin">Admin</a></li>
                        ) : null : null
                    }
                </ul>
                <div className="appearance">
                    <div className={`btn light-dark ${buttonColor[4].active ? "active ": ""}`} onClick={e => handleDarkModeClick(e)}>
                        <i className={!buttonColor[4].active ? "fas fa-moon" : "fas fa-sun"} />
                    </div>
                    {!buttonColor[4].active ? (<div className={`color-icon ${isPaletteClick ? "open" : ""}`} >
                        {/* <div className="icons" onClick={() => handlePaletteClick()}>
                            <i className="fas fa-palette" />
                            <i className={`fas fa-sort-down ${isPaletteClick ? "arrow" : ""}`} />
                        </div> */}
                        {/* <div className={`color-box`}>
                            <h3>Color Switcher</h3>
                            <div className="color-switchers">
                                {buttonColor.map((value, index) => {
                                    if(value.color !=== 'light-dark') {
                                        return (
                                            <button key={index} className={`btn ${value.color} ${value.active? "active" : ""}`} onClick={value => handleChangeTheme(value)}></button>
                                        )
                                    }
                                })}
                            </div>
                        </div> */}
                    </div>) : null
                    }{ user ? (
                    <div className={`user-icon ${isPaletteClick ? "open" : ""}`}
                            onMouseEnter={() => handlePaletteClick()}
                            onMouseLeave={() => handlePaletteClick()}
                    >
                        <div className='btn user'>
                            <i className="fas fa-user" />
                        </div>
                        <div className="user-box">
                            <ul className="user-menu">
                                <li className="user-name">
                                    <label className="user-name">{user.userName}</label>
                                </li>
                                <li><a onClick={() => onClickLogOut()}>logout</a></li>
                            </ul>
                        </div>
                    </div>) : null}
                </div>
            </div>
        </nav>
    );
};

export default Header;