import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PrivateRoute from './helper/PrivateRoute';
import AdminPage from './pages/AdminPage';
import LoginPage from './pages/LoginPage';
import MainPage from './pages/MainPage';
import EventTypeViewPage from './pages/EventTypeViewPage';
import FailedEventPage from './pages/FailedEventPage';

const App = () => {
  return (
    <>
      <Route exact path= "/">
        <Redirect to="/home" />
      </Route>
      <Route component={LoginPage} path="/login" />
      <PrivateRoute component={MainPage} path="/home" />
      <PrivateRoute component={AdminPage} path="/admin" />
      <PrivateRoute component={EventTypeViewPage} path="/singleview" />
      <PrivateRoute component={FailedEventPage} path="/failed" />
    </>
  )
}

export default App;