const palette = {
    'gray': [
      '#f8f9fa',
      '#f1f3f5',
      '#e9ecef',
      '#dee2e6',
      '#ced4da',
      '#adb5bd',
      '#868e96',
      '#495057',
      '#343a40',
      '#212529',
    ],
    'cyan': [
      '#e3fafc',
      '#c5f6fa',
      '#99e9f2',
      '#66d9e8',
      '#3bc9db',
      '#22b8cf',
      '#15aabf',
      '#1098ad',
      '#0c8599',
      '#0b7285',
    ],
    'blue': {
      white: '#fff',
      black: '#24292d',
      navMain: '#4070f4',
      switchersMain: '#0b3cc1',
      body: '#f0f8ff'
    },
    'orange': {
      white: '#fff',
      black: '#24292d',
      navMain: '#f79f1f',
      switchersMain: '#dd8808',
      body: '#fef5e6'
    },
    'purple': {
      white: '#fff',
      black: '#24292d',
      navMain: '#8e44ad',
      switchersMain: '#783993',
      body: '#eadaf1'
    },
    'green': {
      white: '#fff',
      black: '#24292d',
      navMain: '#3a9943',
      switchersMain: '#2a6f31',
      body: '#daf1dc'
    },
    'light-dark': {
      white: '#e4e6eb',
      black: '#24292d',
      navMain: '#24292d',
      switchersMain: '#24292d',
      body: '#242526'
    }
  };
  
  export default palette;