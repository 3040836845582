import React, { useEffect, useState } from 'react';
import AdminForm from '../../components/admin/AdminForm';
import { check } from '../../modules/user';
import Popup from '../../components/common/Popup';
import { useDispatch, useSelector } from 'react-redux';
import { getUserList } from '../../modules/admin';
import { initializeForm } from '../../modules/user';
import { useHistory } from 'react-router-dom';

const AdminContainer = () => {
  const dispatch = useDispatch();
  const [isPopup, setIsPopup] = useState(false);
  const history = useHistory();
  const { loading, userList, user, tokenCheck, tokenCheckError, adminError } = useSelector(({ user, admin, loading }) => ({
    loading: loading["admin/GET_USER_LIST"],
    userList: admin.userList,
    user: admin.user,
    adminError: admin.adminError,
    tokenCheck: user.check,
    tokenCheckError: user.checkError,
  }));

  useEffect(() => {
    if(localStorage.getItem('token')) {
      dispatch(
        check()
      )
    }
  },[dispatch]);

  useEffect(() => {
    if(tokenCheckError) {
        localStorage.removeItem('token');
        localStorage.removeItem('edAppList');
        localStorage.removeItem('user');
        dispatch(
            initializeForm('user')
        )
        history.push('/login');
    }
  })

  useEffect(() => {
    dispatch(
      getUserList()
    )
  },[dispatch]);
  

  const onUserClick = (userId) => {
    setIsPopup(true);
  };
  const onClose = () => {
    setIsPopup(false);
  };
  return (
    <>
      <AdminForm 
        onUserClick={onUserClick} 
        loading={loading}
        userList={userList}
        adminError={adminError}
        />
      <Popup onClose={onClose} isPopup={isPopup}>
      </Popup>
    </>
  );
};

export default AdminContainer;