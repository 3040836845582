import React from 'react';
import Input from './Input';
import Select from './Select';
import CheckBoxList from './CheckBoxList';
import { schoolType, serverType, timeRange } from '../../modules/sample/sample';
import TagsInput from './TagsInput';

const SearchFilter = ({
  tagsInputValue,
  sticky,
  search,
  loading,
  user,
  onChange,
  onClickCheckBox,
  onClickRadioBox,
  isAllCheck,
  handleSelectOptionClick,
  handleAddEdAppName,
  onChangeEdAppNameInput,
  handleRemoveEdAppName,
  onSearchClick,
  type,
}) => {
  return (
    <div className="filter-boxes">
      <div className={`box ${sticky ? 'sticky' : ''}`}>
        <div className="title">Search</div>
        <div className="filter-details">
          <table>
            <tbody>
              <tr>
                {}
                <td width="20%">
                  <Select
                    placeholder="SCHOOL_TYPE"
                    id="schoolDiv"
                    options={schoolType}
                    value={search.schoolDiv.name}
                    handleSelectOptionClick={handleSelectOptionClick}
                  />
                </td>
                <td width="20%">
                  <Select
                    placeholder="DEV/OPS"
                    id="devOpsDiv"
                    options={serverType}
                    value={search.devOpsDiv.name}
                    handleSelectOptionClick={handleSelectOptionClick}
                  />
                </td>
                <td width="20%">
                  <Input
                    name="actorId"
                    placeholder="ACTOR_ID"
                    value={search.actorId}
                    onChange={onChange}
                  />
                </td>
                <td width="20%">
                  {/* <Select
                    placeholder="ED_APP_NAME"
                    id="edAppId"
                    options={search.selectEdAppList}
                    value={search.edAppId.name}
                    handleSelectOptionClick={handleSelectOptionClick}
                  /> */}
                  {/* <Input
                  name="edAppId" 
                  placeholder="ED_APP_NAME"
                  value={search.edAppId}
                  onChange={onChange} /> */}
                  <TagsInput 
                    onChangeEdAppNameInput={onChangeEdAppNameInput}
                    tagsInputValue={tagsInputValue}
                    edAppNameList={search.edAppNameList}
                    handleAddEdAppName={handleAddEdAppName}
                    handleRemoveEdAppName={handleRemoveEdAppName}
                  />
                </td>
                {
                  type === "failed" ? (
                    <Select
                      placeholder="timeRange"
                      id="timeRange"
                      options={timeRange}
                      value={search.timeRange.name}
                      handleSelectOptionClick={handleSelectOptionClick}
                    />
                  ) : null
                }
              </tr>
              <tr>
                <td colSpan={5}>
                  <CheckBoxList
                    list={search.eventType}
                    onClickCheckBox={onClickCheckBox}
                    onClickRadioBox={onClickRadioBox}
                    isAllCheck={isAllCheck}
                    type={type}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <button onClick={() => onSearchClick()} disabled={loading}>{loading? "Loading" : "Search"}</button>
      </div>
    </div>
  );
};

export default SearchFilter;
