import React, { useEffect, useState } from 'react';

const TagsInput = ({ tagsInputValue, edAppNameList, handleAddEdAppName, onChangeEdAppNameInput, handleRemoveEdAppName }) => {
  const [ isActive, setIsActive ] = useState(false);
  const [ isValueHave, setIsValueHave ] = useState(false);

  const handleActive = e => {
    const { value } = e.target;

    setIsActive(!isActive);
    if(value) {
      setIsValueHave(true);
    } else {
      setIsValueHave(false);
    }
  }
  return (
    <div className="tag-box-content">
        <div className="tag-box">
            <label className={`tag-label ${isActive ? "active" : edAppNameList.length > 0 || isValueHave ? "have" : ""}`}>ED_APP_NAME</label>
            <ul>
                {
                  edAppNameList.map((value, index) => {
                    return (
                      <li key={index}>{value}<i className="fas fa-times" onClick={() => handleRemoveEdAppName(value)} /></li>
                    )
                  })
                }
                <input type="text" value={tagsInputValue} onBlur={e => handleActive(e)} onFocus={e => handleActive(e)} onChange={e => onChangeEdAppNameInput(e)} onKeyDown={e => handleAddEdAppName(e)}/>
            </ul>
                <div className={`activeline ${isActive ? "active" : ""}`}></div>
        </div>
    </div>
  );
};

export default TagsInput;
