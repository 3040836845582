import { combineReducers } from "redux";
import { all } from 'redux-saga/effects';
import auth, { authSaga } from "./auth";
import user, { userSaga } from "./user";
import loading from "./loading";
import multiSearch, { searchSaga } from "./search";
import admin, { adminSaga} from "./admin";

const rootReducer = combineReducers({
    auth,
    loading,
    user,
    multiSearch,
    admin,
});

export function* rootSaga() {
    yield all([authSaga(), userSaga(), searchSaga(), adminSaga()]);
}

export default rootReducer;