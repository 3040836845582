import { call, put } from 'redux-saga/effects';
import { startLoading, finishLoading } from '../modules/loading';

export default function createRequestSaga(type, request) {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;

    return function*(action) {
        yield put(startLoading(type));
        try {
            const response = yield call(request, action.payload);
            if(response.data.status === 200)
                yield put({
                    type: SUCCESS,
                    payload: response.data,
                });
            else if(response.data.status === 400)
                yield put({
                    type: FAILURE,
                    payload: response.data.data,
                    error: true,
                });
        } catch (e) {
            console.log(e)
            yield put({
                type: FAILURE,
                payload: e,
                error: true,
            });
        }
        yield put(finishLoading(type));
    };
};

export const createRequestActionTypes = type => {
    const SUCCESS = `${type}_SUCCESS`;
    const FAILURE = `${type}_FAILURE`;
    return [type, SUCCESS, FAILURE];
};