import React from "react";
import MainTemplate from "../components/main/MainTemplate";
import FailedEventContainer from "../containers/eventview/FailedEventContainer";

const FailedEventPage = () => {
    return (
        <MainTemplate>
            <FailedEventContainer />
        </MainTemplate>
    )
}

export default FailedEventPage;