import client from "./client";

function custom_sort(a, b) {
    return -(new Date(a.eventtime).getTime() - new Date(b.eventtime).getTime());
}


// Multi Search
export const multiSearch = (filter) => {
    let request = {
        devOpsDiv: filter.devOpsDiv.value,
        dataCnt: filter.dataCnt,
        schoolDiv: filter.schoolDiv.value,
        actorId: filter.actorId,
        edAppName: "",
    };
    if(filter.edAppNameList.length > 0) {
        request.edAppName = filter.edAppNameList.join('|');
    }

    filter.eventType.map((value) => {
        if(value.value) {
            request = {
                ...request,
                [value.name]: "Y"
            }
        }
        return;
    })
    let responseData = null;
    let result = [];
    return client.post('/event/success/multi', request)
    .then(response => {
        responseData = response;
        result = [...response.data.data];

        result.sort(custom_sort);
        responseData.data.data = result;
    })
    .then(response => {
        return responseData;
    })
}
// Single Search
export const singleSearch = (filter) => {
    let request = {
        devOpsDiv: filter.devOpsDiv.value,
        dataCnt: filter.dataCnt,
        schoolDiv: filter.schoolDiv.value,
        actorId: filter.actorId,
        edAppName: "",
        eventName: ""
    };

    filter.eventType.map((value) => {
        if(value.value) {
            request = {
                ...request,
                eventName: value.name,
            }
        }
        return;
    })

    if(filter.edAppNameList.length > 0) {
        request.edAppName = filter.edAppNameList.join('|');
    }
    let responseData = null;
    let result = [];
    return client.post('/event/success/single', request)
    .then(response => {
        responseData = response;
        result = [...response.data.data];

        result.sort(custom_sort);
        responseData.data.data = result;
    })
    .then(response => {
        return responseData;
    })
}

// Failed Search
export const failedSearch = (filter) => {
    let request = {
        devOpsDiv: filter.devOpsDiv.value,
        dataCnt: filter.dataCnt,
        schoolDiv: filter.schoolDiv.value,
        actorId: filter.actorId,
        edAppName: "",
        timeRange: filter.timeRange.value
    };
    if(filter.edAppNameList.length > 0) {
        request.edAppName = filter.edAppNameList.join('|');
    }

    filter.eventType.map((value) => {
        if(value.value) {
            request = {
                ...request,
                [value.name]: "Y"
            }
        }
        return;
    })
    let responseData = null;
    let result = [];
    return client.post('/event/fail/multi', request)
    .then(response => {
        responseData = response;
        result = [...response.data.data];

        result.sort(custom_sort);
        responseData.data.data = result;
    })
    .then(response => {
        return responseData;
    })
}