import React from 'react';

const textMap = {
    login: 'LOGIN',
    register: '회원가입'
}

const AuthForm = ({ type, form, onChange, onSubmit, error, loading }) => {
    const text = textMap[type];
    return (
        <>
            <h3>{text}</h3>
            <form onSubmit={onSubmit}>
                <input 
                    autoComplete="off" 
                    name="userId" 
                    placeholder="ID" 
                    onChange={onChange}
                    value={form.userId}    
                />
                <input
                    autoComplete="new-password" 
                    name="userPw"
                    placeholder="Password"
                    type="password"
                    onChange={onChange}
                    value={form.userPw}
                />
                {type === 'register' && (
                    <input
                        autoComplete="new-password"
                        name="passwordConfirm"
                        placeholder="비밀번호 확인"
                        type="password"
                        onChange={onChange}
                        value={form.passwordConfirm}
                    />
                )}
                {error && <div className="error-msg">{error}</div>}
                <button className="full-width" disabled={loading}>{ loading? "Loading" : text }</button>
            </form>
            {/* <div className="footer">
                {type === 'login' ? (
                    <Link to="/register">회원 가입</Link>
                ) : (
                    <Link to="login">로그인</Link>
                )}
            </div> */}

        </>
    )
}

export default AuthForm;