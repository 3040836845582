import { createAction, handleActions } from "redux-actions";
import { takeLatest } from 'redux-saga/effects';
import * as authAPI from '../lib/api/auth';
import createRequestSaga, { createRequestActionTypes } from "../lib/createRequestSaga";

const SET_USER = 'user/SET_USER';
const INITIALIZE_FORM = 'user/INITIALIZE_FORM';

const [CHECK, CHECK_SUCCESS, CHECK_FAILURE] = createRequestActionTypes(
    'user/CHECK',
)

export const SetUser = createAction(SET_USER, user => user);
export const check = createAction(CHECK);
export const initializeForm = createAction(INITIALIZE_FORM, form => form);

const checkSaga = createRequestSaga(CHECK, authAPI.check);
export function* userSaga() {
    yield takeLatest(CHECK, checkSaga);
}


const initialState = {
    user: null,
    check: null,
    checkError: false,
};

export default handleActions(
    {
        [INITIALIZE_FORM]: (state, { payload: form }) => ({
            ...state,
            [form]: initialState[form],
            checkError: null,
        }),
        [SET_USER]: (state, { payload: user }) => ({
            ...state,
            user,
            check: null,
        }),
        [CHECK_SUCCESS]: (state, { payload: res }) => ({
            ...state,
            check: res.status,
            checkError: null,
        }),
        [CHECK_FAILURE]: (state, { payload: error}) => ({
            ...state,
            user: null,
            checkError: true,
        }),
    },
    initialState,
);