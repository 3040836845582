import React from 'react';
import HeaderContainer from '../../containers/common/HeaderContainer';

const MainTemplate = ({ children }) => {
    return (
        <>
            <HeaderContainer />
            <div className="home-content">
                {children}
                <div className="footer">
                    Copyright 2021, i-Screamedu Co. All rights reserved.
                </div>
            </div>
        </>
    );
};

export default MainTemplate;