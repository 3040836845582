import React from 'react';
import MainTemplate from '../components/main/MainTemplate';
import EventTypeViewContainer from '../containers/eventview/EventTypeViewContainer';

const EventTypeViewPage = () => {
  return (
    <MainTemplate>
      <EventTypeViewContainer />
    </MainTemplate>
  );
};

export default EventTypeViewPage;
