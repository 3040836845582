import { createAction, handleActions } from "redux-actions";
import produce from "immer";
import { takeLatest } from "redux-saga/effects";
import createRequestSaga, { createRequestActionTypes } from "../lib/createRequestSaga";
import * as adminAPI from '../lib/api/admin';


const CHANGE_FIELD = 'admin/CHANGE_FIELD';
const INITIALIZE_SIGN_UP_FORM = 'admin/INITIALIZE_SIGN_UP_FORM';

const [GET_USER_LIST, GET_USER_LIST_SUCCESS, GET_USER_LIST_FAILURE] = createRequestActionTypes(
    'admin/GET_USER_LIST',
);

const [GET_USER, GET_USER_SUCCESS, GET_USER_FAILURE] = createRequestActionTypes(
    'admin/GET_UESR',
);

export const changeSignUpForm = createAction(
    CHANGE_FIELD, ({ form, key, value}) => ({
        form,
        key,
        value
    }),
);

export const initializeSignUpForm = createAction(INITIALIZE_SIGN_UP_FORM, form => form);
export const getUserList = createAction(GET_USER_LIST);
export const getUser = createAction(GET_USER);

const getUserListSaga = createRequestSaga(GET_USER_LIST, adminAPI.userList);
const getUserSaga = createRequestSaga(GET_USER, adminAPI.user);
// const multiSearchSaga = createRequestSaga(MULTI_SEARCH, searchAPI.multiSearch);
// const singleSearchSaga = createRequestSaga(SINGLE_SEARCH, searchAPI.singleSearch);
export function* adminSaga() {
    yield takeLatest(GET_USER_LIST, getUserListSaga);
    yield takeLatest(GET_USER, getUserSaga);
}

const initialState = {
    userList: [],
    user: {
        userId: "",
        email: "",
        userPw: "",
        adminYn: "",
        edAppList: [],
    },
    adminError: null,
};

const admin = handleActions(
    {
        [CHANGE_FIELD]: (state, { payload: { form, key, value } }) =>
            produce(state, draft => {
                draft[form][key] = value;
            }),
        [INITIALIZE_SIGN_UP_FORM]: (state, { payload: form }) => ({
            ...state,
            [form]: initialState[form],
            adminError: null,
        }),
        [GET_USER_LIST_SUCCESS]: (state, { payload: userList }) => ({
            ...state,
            userList: userList.data,
            adminError: null,
        }),
        [GET_USER_LIST_FAILURE]: (state, { payload: error }) => ({
            ...state,
            userList: [],
            adminError: error,
        }),
        [GET_USER_SUCCESS]: (state, { payload: user }) => ({
            ...state,
            user: user.data,
            adminError: null,
        }),
        [GET_USER_FAILURE]: (state, { payload: error }) => ({
            ...state,
            adminError: error,
        }),
    },
    initialState,
)

export default admin;