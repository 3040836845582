import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import EventTypeViewForm from '../../components/eventview/EventTypeViewForm';
import search, {
  changeSearchField,
  initializeFilterForm,
  singleSearch,
} from '../../modules/search';
import { initializeForm } from '../../modules/user';
import { check } from '../../modules/user';

const EventTypeViewContainer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    filter,
    eventLog,
    searchError,
    tokenCheck,
    tokenCheckError,
    loading,
  } = useSelector(({ multiSearch, user, loading }) => ({
    filter: multiSearch.filter,
    eventLog: multiSearch.eventLog,
    searchError: multiSearch.searchError,
    tokenCheck: user.check,
    tokenCheckError: user.checkError,
    loading: loading['search/SINGLE_SEARCH'],
  }));
  const [eventLogHeader, setEventLogHeader] = useState([]);
  const [ firstEventLog, setFirstEventLog ] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      dispatch(check());
    }
  }, []);

  useEffect(() => {
    const eventType = localStorage.getItem('eventType');
    if (eventType) {
      let nextRadioBoxList = [];
      filter.eventType.map((value) => {
        if (value.label === eventType) {
          nextRadioBoxList.push({ ...value, value: true });
        } else {
          nextRadioBoxList.push({ ...value, value: false });
        }
      });
      dispatch(
        changeSearchField({
          form: 'filter',
          key: 'eventType',
          value: nextRadioBoxList,
        }),
      );
    } else {
      let nextRadioBoxList = [];
      filter.eventType.map((value) => {
        if (value.label === "session") {
          nextRadioBoxList.push({ ...value, value: true });
        } else {
          nextRadioBoxList.push({ ...value, value: false });
        }
      });
      dispatch(
        changeSearchField({
          form: 'filter',
          key: 'eventType',
          value: nextRadioBoxList,
        }),
      );
    }

    setFirstEventLog(true);
  }, [dispatch]);

  useEffect(() => {
    if (eventLog.length > 0) {
      let eventLogHeader = [
        'EVENT_TIME',
        'ACTION_TYPE',
        'ACTOR_ID',
        'ED_APP_NAME',
        ...Object.keys(eventLog[0]),
      ].filter(
        (value) =>
          value !== 'actorId' &&
          value !== 'action' &&
          value !== 'edAppName' &&
          value !== 'type' &&
          value !== 'eventtime',
      );

      setEventLogHeader(eventLogHeader);
    }
  }, [dispatch, eventLog]);

  useEffect(() => {
    if(firstEventLog) {
      dispatch(
        singleSearch(
          filter
        )
      )
    }
  }, [firstEventLog])

  useEffect(() => {
    if (tokenCheckError) {
      localStorage.removeItem('token');
      localStorage.removeItem('edAppList');
      localStorage.removeItem('user');
      dispatch(initializeForm('user'));
      history.push('/login');
    }
  }, [dispatch, tokenCheck, tokenCheckError]);

  const handleSelectOptionClick = (id, value) => {
    dispatch(
      changeSearchField({
        form: 'filter',
        key: id,
        value: value.value,
      }),
    );
  };

  const onClickActorId = (value) => {
    dispatch(
      changeSearchField({
        form: 'filter',
        key: 'actorId',
        value,
      }),
    );
  };

  const onClickEdAppName = (value) => {
    let newEdAppNameList = [...filter.edAppNameList];
    newEdAppNameList.push(value);
    newEdAppNameList = [...new Set(newEdAppNameList)];
    dispatch(
      changeSearchField({
        form: 'filter',
        key: 'edAppNameList',
        value: newEdAppNameList,
      }),
    );
  };
  return (
    <EventTypeViewForm
      eventLogHeader={eventLogHeader}
      filter={filter}
      loading={loading}
      eventLog={eventLog}
      searchError={searchError}
      onClickActorId={onClickActorId}
      onClickEdAppName={onClickEdAppName}
      handleSelectOptionClick={handleSelectOptionClick}
    />
  );
};

export default EventTypeViewContainer;
