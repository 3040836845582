import React from 'react';
import LoadingSpinner from './LoadingSpinner';

const Popup = ({isPopup, onClose, children}) => {
    return (
        <div className={`popup ${isPopup? 'visible' : ''}`}>
            <div className="popup-pannel" >
                <div className="popup-inner">
                    <i className="fas fa-times close" onClick={() => onClose()}></i>
                    { children }
                </div>
            </div>
        </div>
    )
}

export default Popup;