import React from 'react';
import MainTemplate from '../components/main/MainTemplate';
import AdminContainer from '../containers/admin/AdminContainer';

const AdminPage = () => {
  return (
    <MainTemplate>
      <AdminContainer />
    </MainTemplate>
  );
};

export default AdminPage;
