import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { changeField, initializeForm, login } from '../../modules/auth';
import AuthForm from '../../components/auth/AuthForm';
import { check, SetUser } from '../../modules/user';
import { changeSearchField, initializeFilterForm } from '../../modules/search';

const LoginContainer = ({ history }) => {
    const [error, setError] = useState(null);
    const dispatch = useDispatch();
    const { form, auth, authError, user, tokenCheck, tokenCheckError, loading } = useSelector(({ auth, user, loading }) => ({
        form: auth.login,
        auth: auth.auth,
        authError: auth.authError,
        user: user.user,
        tokenCheck: user.check,
        tokenCheckError: user.checkError,
        loading: loading["auth/LOGIN"],
    }));

    const onChange = e => {
        const { value, name } = e.target;
        dispatch(
            changeField({
                form: 'login',
                key: name,
                value
            })
        );
    };

    const onSubmit = e => {
        e.preventDefault();
        const { userId, userPw } = form;
        dispatch(login({ userId, userPw }));
    };

    useEffect(() => {
        if(localStorage.getItem('token')) {
            dispatch(
                check()
            )
        }
    },[dispatch])

    useEffect(() => {
        if(tokenCheckError) {
            localStorage.removeItem('token');
            localStorage.removeItem('edAppList');
            localStorage.removeItem('user');
        }
        if(tokenCheck === 200)
            history.push('/home');
    },[dispatch, tokenCheck, tokenCheckError])

    useEffect(() => {
        dispatch(initializeForm('login'));
    }, [dispatch]);
    
    useEffect(() => {
        if (authError) {
            console.error('오류 발생');
            console.error(authError);
            setError('로그인 실패');
            return;
        }
        if (auth) {
            console.log('로그인 성공');

            localStorage.setItem('token', auth.data.token);

            dispatch(
                initializeFilterForm(
                    'filter'
                )
            )
            dispatch(
                SetUser({
                    adminYn: auth.data.adminYn,
                    email: auth.data.email,
                    userName: auth.data.userName,
                })
            );
        }
    }, [auth, authError, dispatch]);

    useEffect(() => {
        if (user) {
            history.push('/home');
            try {
                localStorage.setItem('user', JSON.stringify(user));
            } catch (e) {
                console.log('localStorage is not working');
            }
        }
    }, [history, user]);

    return (
        <AuthForm
            type="login"
            form={form}
            onChange={onChange}
            onSubmit={onSubmit}
            error={error}
            loading={loading}
        />
    );
};

export default withRouter(LoginContainer);